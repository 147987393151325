import { jsx as _jsx } from "react/jsx-runtime";
import { Intent, Tag } from '@blueprintjs/core';
import { css, cx } from 'linaria';
import { CampaignStatusType } from './api';
const clsStatus = css `
  vertical-align: middle;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  margin-left: 15px;
  &:first-child {
    margin-left: 0;
  }
`;
const statusProps = {
    [CampaignStatusType.CAMPAIGN_STATUS_ENDED]: {
        label: 'Ended',
        intent: Intent.NONE,
    },
    [CampaignStatusType.CAMPAIGN_STATUS_ACTIVE]: {
        label: 'Active',
        intent: Intent.SUCCESS,
    },
    [CampaignStatusType.CAMPAIGN_STATUS_SCHEDULED]: {
        label: 'Scheduled',
        intent: Intent.PRIMARY,
    },
};
export const CampaignStatus = ({ campaign, className }) => {
    var _a;
    const status = (_a = campaign.status) !== null && _a !== void 0 ? _a : CampaignStatusType.CAMPAIGN_STATUS_ENDED;
    const { label, intent } = statusProps[status];
    return (_jsx(Tag, { className: cx(clsStatus, className), intent: intent, large: true, children: label }));
};
