import { jsx as _jsx } from "react/jsx-runtime";
import { css, cx } from 'linaria';
const clsCardDescription = css `
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 20px;
  &:first-child {
    margin-top: 0;
  }
`;
export const CardDescription = ({ children, className }) => (_jsx("p", { className: cx(clsCardDescription, className), children: children }));
