import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isPresent } from '@whisklabs/typeguards';
import { css, cx } from 'linaria';
import { RouteSwitch } from 'common/components/routes';
import { clsPageContainer } from 'common/styles/layout';
import { Header } from './header';
const clsAdmin = css `
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;
const clsContent = css `
  flex: 1 1 auto;
  display: flex;
`;
export const AdminLayout = ({ items: itemsProp, defaultGuard, defaultPath, className }) => {
    const items = itemsProp.filter(isPresent);
    return (_jsxs("div", { className: cx(clsAdmin, className), children: [_jsx(Header, { navItems: items.map((item) => ({ to: item.path, label: item.label })) }), _jsx("div", { className: cx(clsContent, clsPageContainer), children: _jsx(RouteSwitch, { routes: items, defaultGuard: defaultGuard, defaultPath: defaultPath }) })] }));
};
