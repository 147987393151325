import { jsx as _jsx } from "react/jsx-runtime";
import { Button, FormGroup, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { UserRoleType } from 'b2b/users/api';
import { userRoleLabels } from 'b2b/users/common/user-role';
import { getFieldFormGroupProps } from 'common/components/form/helpers';
import { sharedPopoverProps } from 'common/popover';
const clsRoleButton = css `
  min-width: 100% !important;
`;
const FieldRole = Select.ofType();
const selectItems = [
    UserRoleType.USER_ROLE_BASIC,
    UserRoleType.USER_ROLE_EDITOR,
    UserRoleType.USER_ROLE_ADMIN,
];
const roleItemsProps = {
    [UserRoleType.USER_ROLE_BASIC]: { label: userRoleLabels[UserRoleType.USER_ROLE_BASIC] },
    [UserRoleType.USER_ROLE_EDITOR]: { label: userRoleLabels[UserRoleType.USER_ROLE_EDITOR] },
    [UserRoleType.USER_ROLE_ADMIN]: { label: userRoleLabels[UserRoleType.USER_ROLE_ADMIN] },
};
const renderRole = (item, { handleClick, modifiers }) => modifiers.matchesPredicate ? (_jsx(MenuItem, { active: modifiers.active, onClick: handleClick, text: roleItemsProps[item].label }, `${item}`)) : null;
export const UserFieldRole = observer(({ field, label }) => (_jsx(FormGroup, { label: label, ...getFieldFormGroupProps(field), children: _jsx(FieldRole, { items: selectItems, itemRenderer: renderRole, onItemSelect: field.handleValueChange, popoverProps: sharedPopoverProps, filterable: false, children: _jsx(Button, { className: clsRoleButton, text: roleItemsProps[field.value].label, alignText: "left", rightIcon: "double-caret-vertical", fill: true, large: true }) }) })));
