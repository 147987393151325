import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isPresent, isString } from '@whisklabs/typeguards';
import { css, cx } from 'linaria';
import { clsMb30 } from 'common/styles/margin-padding';
import { PageDescription, clsPageDescription } from './page-description';
import { PageTitle } from './page-title';
const clsPageHeader = css `
  & .${clsPageDescription} {
    margin: 0;
  }
`;
const clsTitleRow = css `
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`;
const clsTitleSide = css `
  margin-left: 30px;
`;
export const PageHeader = ({ title, rightElement, description }) => (_jsxs("div", { className: cx(clsPageHeader, clsMb30), children: [_jsxs("div", { className: clsTitleRow, children: [isString(title) ? _jsx(PageTitle, { title: title }) : title, isPresent(rightElement) ? _jsx("div", { className: clsTitleSide, children: rightElement }) : null] }), isString(description) ? _jsx(PageDescription, { description: description }) : description] }));
