import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NonIdealState } from '@blueprintjs/core';
import { isDefined } from '@whisklabs/typeguards';
import { css, cx } from 'linaria';
import notFoundImage from './not-found-image.png';
const clsNotFound = css `
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: auto;
  height: 100%;
  min-height: 100%;
`;
export const NotFoundPage = ({ goToLink, className }) => (_jsx("div", { className: cx(clsNotFound, className), children: _jsx(NonIdealState, { icon: _jsx("img", { src: notFoundImage, alt: "Not Found" }), title: "404: Page not found", action: isDefined(goToLink) ? _jsxs("div", { children: ["Go to ", goToLink] }) : undefined }) }));
