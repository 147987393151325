import { jsx as _jsx } from "react/jsx-runtime";
import { Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { css } from 'linaria';
const SortSelect = Select.ofType();
const clsPop = css `
  .bp3-select-popover .bp3-menu {
    max-height: 500px;
  }
`;
export const SortSel = ({ items, active, onSelect }) => (_jsx(SortSelect, { items: items, itemRenderer: (i, { handleClick, modifiers }) => (_jsx(MenuItem, { active: modifiers.active, text: i.sortTitle, icon: i.sortOrder === 'desc' ? 'sort-numerical-desc' : 'sort-numerical', onClick: handleClick }, i.sortKey + i.sortOrder)), filterable: false, onItemSelect: onSelect, activeItem: active, popoverProps: {
        portalClassName: clsPop,
    }, children: _jsx(Button, { text: `Sort: ${active.sortTitle}`, icon: active.sortOrder === 'desc' ? 'sort-numerical-desc' : 'sort-numerical' }) }));
