import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '@blueprintjs/core';
import { isText } from '@whisklabs/typeguards';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useAppDetailsStoreContext } from 'b2b/apps/details/context';
import { Card, CardTitle } from 'common/components/card';
import { AppCredentialsField } from './field';
const clsContent = css `
  display: flex;
  align-items: center;
`;
const clsFields = css `
  flex: 1 1 auto;
`;
const clsVisibilityButton = css `
  margin-left: auto;
`;
export const AppCredentials = observer(() => {
    var _a;
    const { app } = useAppDetailsStoreContext();
    const [keysHidden, setKeysHidden] = useState(true);
    const { clientId, secret, apiKey } = (_a = app.credentials) !== null && _a !== void 0 ? _a : {};
    const keys = [
        { value: clientId, label: 'Client ID' },
        { value: secret, label: 'Secret' },
        { value: apiKey, label: 'API Key' },
    ];
    return (_jsx(Card, { header: _jsxs(_Fragment, { children: [_jsx(CardTitle, { children: "Credentials" }), _jsxs(Button, { className: clsVisibilityButton, onClick: () => setKeysHidden((curKeysHidden) => !curKeysHidden), icon: keysHidden ? 'eye-open' : 'eye-off', children: [keysHidden ? 'Show' : 'Hide', " keys"] })] }), children: _jsx("div", { className: clsContent, children: _jsx("div", { className: clsFields, children: keys.map(({ value, label }) => isText(value) ? _jsx(AppCredentialsField, { value: value, label: label, isHidden: keysHidden }, label) : null) }) }) }));
});
