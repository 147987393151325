import { jsx as _jsx } from "react/jsx-runtime";
import { Button, Intent } from '@blueprintjs/core';
import { css } from 'linaria';
import { matchSorter } from 'match-sorter';
import { observer } from 'mobx-react-lite';
import { useContext, useMemo } from 'react';
import { communityPath, conversationPath, dishPath, ingredientPath, profilePath, recipePath } from 'b2c/helpers/routes';
import { Table } from 'common/components/client-pagination-table';
import { NonEmptyString } from 'common/components/non-empty-string';
import { DATE_FORMAT_NUMERIC } from 'common/config';
import { formatDate } from 'common/helpers/date';
import { createSearchFunction } from 'common/helpers/search';
import { stringCompareAsc } from 'common/helpers/sort';
import { clsLink } from 'common/styles/link';
import { RecordType } from '../../types';
import { IndexingToolContext } from '../context';
const clsButton = css `
  text-align: center;

  & + & {
    margin-left: 30px;
  }
`;
const clsTable = css `
  word-break: break-all;
`;
const recordMap = {
    [RecordType.DIRECTORY_RECORD_TYPE_COMMUNITY]: communityPath,
    [RecordType.DIRECTORY_RECORD_TYPE_COMMUNITY_CONVERSATION]: conversationPath,
    [RecordType.DIRECTORY_RECORD_TYPE_COMMUNITY_RECIPE]: recipePath,
    [RecordType.DIRECTORY_RECORD_TYPE_PROFILE]: profilePath,
    [RecordType.DIRECTORY_RECORD_TYPE_DISH]: dishPath,
    [RecordType.DIRECTORY_RECORD_TYPE_FOODPEDIA_PRODUCT]: ingredientPath,
};
const searchFunction = createSearchFunction([({ recordId }) => recordId, { key: (item) => [item.recordId, item.name] }], { threshold: matchSorter.rankings.CONTAINS });
export const RulesListTable = observer(() => {
    const { listStore, controlStore: { recordType }, } = useContext(IndexingToolContext);
    const columns = useMemo(() => [
        {
            id: 'recordId',
            title: 'Record Id',
            width: '420px',
            sorter: (a, b) => stringCompareAsc(a.recordId, b.recordId),
            render: (rule) => (_jsx("a", { className: clsLink, href: recordMap[recordType](rule.recordId), rel: "noreferrer", target: "_blank", children: _jsx(NonEmptyString, { value: rule.recordId }) })),
        },
        {
            id: 'name',
            title: 'Record Name',
            sorter: (a, b) => stringCompareAsc(a.name, b.name),
            render: (rule) => _jsx(NonEmptyString, { value: rule.name }),
        },
        {
            id: 'createdAtSec',
            width: '140px',
            title: 'Date',
            sorter: (a, b) => stringCompareAsc(a.name, b.name),
            render: (rule) => formatDate(rule.createdAtSec * 1000, DATE_FORMAT_NUMERIC),
        },
        {
            id: 'actions',
            width: '60px',
            title: 'Actions',
            align: 'center',
            render: (rule) => (_jsx(Button, { className: clsButton, rightIcon: "cross", onClick: () => {
                    void listStore.removeRule(rule);
                }, large: true, intent: Intent.DANGER })),
        },
    ], [listStore, recordType]);
    return (_jsx(Table, { loader: listStore.loader, tableClassName: clsTable, cellPaddingVertical: 10, data: listStore.rulesList, withUnstableHeight: true, columns: columns, rowKey: "recordId", searchFunction: searchFunction }));
});
