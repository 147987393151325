import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isPresent } from '@whisklabs/typeguards';
import { css, cx } from 'linaria';
import { useMemo } from 'react';
import { RouteSwitch } from 'common/components/routes';
import { SidebarItem } from './sidebar-item';
const clsDashboard = css `
  display: flex;
  width: 100%;
  min-height: 100%;
`;
const clsSidebar = css `
  margin: 0;
  padding: 40px 8px;
  width: 160px;
  flex: 0 0 auto;

  @media (min-width: 960px) {
    padding: 40px 16px;
    width: 260px;
  }
`;
const clsMain = css `
  flex: 1 1 auto;
  padding: 30px 16px 100px;
  box-shadow: 0px 3px 12px rgba(79, 79, 106, 0.1);
  background: #fff;
`;
export const DashboardLayout = ({ items: itemsProp, defaultGuard, defaultPath, className }) => {
    const items = useMemo(() => itemsProp.filter(isPresent).sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0)), [itemsProp]);
    return (_jsxs("div", { className: cx(clsDashboard, className), children: [_jsx("div", { className: clsSidebar, children: items.map((item) => (_jsx(SidebarItem, { to: item.path, label: item.label, icon: item.icon }, item.path))) }), _jsx("main", { className: clsMain, children: _jsx(RouteSwitch, { routes: items, defaultGuard: defaultGuard, defaultPath: defaultPath }) })] }));
};
