import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from 'linaria';
import { Box } from 'common/components/box';
const clsUser = css `
  font-size: 80%;
  border: 1px solid #ddd;
  background: white;
`;
const clsUserType = css `
  color: #555;
  font-weight: 700;
`;
const clsUserData = css `
  border-left: 1px solid #ddd;
  font-family: monospace;
`;
export const User = ({ type, id, email, name }) => (_jsxs(Box, { className: clsUser, display: "inline-flex", p: 4, borderRadius: "12px", children: [_jsx(Box, { className: clsUserType, pr: 4, children: type }), _jsxs(Box, { className: clsUserData, flexDirection: "column", alignItems: "flex-start", pl: 4, children: [_jsx(Box, { children: id }), _jsx(Box, { children: name }), _jsx(Box, { children: email })] })] }));
