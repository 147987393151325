import { jsx as _jsx } from "react/jsx-runtime";
import { Icon } from '@blueprintjs/core';
import { css, cx } from 'linaria';
import { debounce } from 'lodash';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldInput } from 'common/components/fields/field-input';
const clsSearchField = css `
  width: 400px;
`;
export const DEFAULT_SEARCH_DEBOUNCE_DELAY = 200;
export const Search = memo(({ onChange, debounceDelay = DEFAULT_SEARCH_DEBOUNCE_DELAY, search, className, placeholder = 'Search', }) => {
    const debouncedOnChange = useMemo(() => debounce(onChange, debounceDelay, { leading: true, trailing: true, maxWait: debounceDelay * 2 }), [debounceDelay, onChange]);
    const [localSearch, setLocalSearch] = useState(search);
    useEffect(() => {
        setLocalSearch(search);
    }, [search]);
    useEffect(() => () => debouncedOnChange.cancel(), [debouncedOnChange]);
    const handleSearchChange = useCallback((event) => {
        setLocalSearch(event.currentTarget.value);
        debouncedOnChange(event.currentTarget.value);
    }, [debouncedOnChange]);
    return (_jsx(FieldInput, { type: "search", value: localSearch, onChange: handleSearchChange, placeholder: placeholder, leftElement: _jsx(Icon, { icon: "search" }), className: cx(clsSearchField, className), noMargin: true }));
});
