import { jsx as _jsx } from "react/jsx-runtime";
import { Icon } from '@blueprintjs/core';
import { css } from 'linaria';
import { matchSorter } from 'match-sorter';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { recipePath } from 'b2c/helpers/routes';
import { Table } from 'common/components/client-pagination-table';
import { NonEmptyString } from 'common/components/non-empty-string';
import { COLORS } from 'common/helpers/colors';
import { createSearchFunction } from 'common/helpers/search';
import { boolCompareAsc, stringCompareAsc } from 'common/helpers/sort';
import { clsLink } from 'common/styles/link';
import { Actions } from './actions';
const clsTable = css `
  word-break: break-all;
`;
const searchFunction = createSearchFunction([
    (recipe) => recipe.id,
    { key: (recipe) => recipe.id, threshold: matchSorter.rankings.EQUAL },
]);
export const FlaggedTextsTable = observer(({ store }) => {
    const columns = useMemo(() => [
        {
            id: 'isTrustedUser',
            title: (_jsx("div", { title: "Trusted User", children: _jsx(Icon, { icon: 'endorsed', color: COLORS.GREEN }) })),
            width: '20px',
            sorter: (a, b) => boolCompareAsc(a.isFromTrustedUser, b.isFromTrustedUser),
            render: (recipe) => (_jsx("div", { children: recipe.isFromTrustedUser ? (_jsx(Icon, { icon: 'endorsed', color: COLORS.GREEN })) : (_jsx(Icon, { icon: 'ban-circle', color: COLORS.RED })) })),
        },
        {
            id: 'recipeId',
            title: 'Recipe Id',
            width: '344px',
            sorter: (a, b) => stringCompareAsc(a.id, b.id),
            render: (recipe) => (_jsx("a", { className: clsLink, href: recipePath(recipe.id), rel: "noreferrer", target: "_blank", children: _jsx(NonEmptyString, { value: recipe.id }) })),
        },
        {
            id: 'suspiciousText',
            title: 'Suspicious Text',
            sorter: (a, b) => stringCompareAsc(a.moderationDetailsMessage, b.moderationDetailsMessage),
            render: (recipe) => _jsx(NonEmptyString, { value: recipe.moderationDetailsMessage }),
        },
        {
            id: 'actions',
            width: '140px',
            title: 'Actions',
            align: 'center',
            render: (recipe) => (_jsx(Actions, { recipe: recipe, onApprove: store.approveRecipeText, onReject: store.rejectRecipeText })),
        },
    ], [store.approveRecipeText, store.rejectRecipeText]);
    return (_jsx(Table, { loader: store.loader, tableClassName: clsTable, cellPaddingVertical: 10, data: store.recipes, withUnstableHeight: true, columns: columns, rowKey: "entityId", searchFunction: searchFunction }));
});
