import { jsx as _jsx } from "react/jsx-runtime";
import { Button, Intent, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { sharedPopoverProps } from 'common/popover';
import { AppPublisherLicence } from '../api';
const clsLicenceButton = css `
  min-width: 140px !important;
`;
const LicenceSelect = Select.ofType();
const selectItems = [AppPublisherLicence.LICENCE_TYPE_FAIRUSE, AppPublisherLicence.LICENCE_TYPE_EXTENDED];
const licenceItemsProps = {
    [AppPublisherLicence.LICENCE_TYPE_FAIRUSE]: { intent: Intent.PRIMARY, label: 'Fair Use' },
    [AppPublisherLicence.LICENCE_TYPE_EXTENDED]: { intent: Intent.SUCCESS, label: 'Extended' },
};
const renderLicence = (item, { handleClick, modifiers }) => modifiers.matchesPredicate ? (_jsx(MenuItem, { active: modifiers.active, onClick: handleClick, text: licenceItemsProps[item].label }, `${item}`)) : null;
export const AppPublisherFieldLicence = observer(({ field }) => (_jsx(LicenceSelect, { items: selectItems, itemRenderer: renderLicence, onItemSelect: field.handleValueChange, popoverProps: sharedPopoverProps, filterable: false, disabled: field.isDisabled, children: _jsx(Button, { className: clsLicenceButton, text: licenceItemsProps[field.value].label, alignText: "left", rightIcon: "double-caret-vertical", intent: licenceItemsProps[field.value].intent, disabled: field.isDisabled, fill: true, large: true }) })));
