import { jsx as _jsx } from "react/jsx-runtime";
import { Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { isPresent } from '@whisklabs/typeguards';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { sharedPopoverProps } from 'common/popover';
import { ActionId } from '../types';
const clsActionButton = css `
  min-width: 100% !important;
`;
const clsActionSelect = css `
  &,
  div,
  button {
    height: 100%;
  }
`;
const clsActionItem = css `
  padding: 10px;
`;
const ActionSelect = Select.ofType();
const renderActionItem = (item, { handleClick, modifiers }) => (_jsx(MenuItem, { className: clsActionItem, active: modifiers.active, onClick: handleClick, text: item.text }, item.id));
export const ActionsSelect = observer(({ recipe, onClick }) => {
    const handleClick = useCallback((item) => {
        onClick({ actionId: item.id, recipe });
    }, [recipe, onClick]);
    const items = useMemo(() => [
        {
            id: ActionId.Approve,
            text: isPresent(recipe.community) ? 'Approve recipe for this community' : 'Approve recipe',
        },
        isPresent(recipe.community)
            ? {
                id: ActionId.FlagInCommunity,
                text: 'Leave flagged for this community',
            }
            : undefined,
        {
            id: ActionId.FlagAllCommunities,
            text: 'Flag recipe for all communities',
        },
        {
            id: ActionId.Ban,
            text: 'Ban recipe from Whisk',
        },
    ].filter(isPresent), [recipe.community]);
    return (_jsx(ActionSelect, { items: items, disabled: recipe.loading, itemRenderer: renderActionItem, onItemSelect: handleClick, popoverProps: sharedPopoverProps, filterable: false, className: clsActionSelect, children: _jsx(Button, { className: clsActionButton, disabled: recipe.loading, icon: "more", fill: true, large: true }) }));
});
