import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Intent } from '@blueprintjs/core';
import { css, cx } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { RuleType } from 'b2c/indexing-tool/types';
import { FieldInput } from 'common/components/fields/field-input';
import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { clsMb30 } from 'common/styles/margin-padding';
import { IndexingToolContext } from '../../context';
const inputWrapper = css `
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 20px;
`;
const clsInput = css `
  & > input {
    box-shadow: none;
    height: auto !important;
  }
`;
const clsControls = css `
  flex: 1 0 max-content;
  display: flex;
  margin-top: 10px;
`;
export const FileForm = observer(() => {
    const { fileStore: { form, loader, submit, downloadRules }, controlStore: { ruleType }, } = useContext(IndexingToolContext);
    const isIndexed = ruleType === RuleType.DIRECTORY_RULE_TYPE_ALWAYS_INDEX;
    return (_jsxs(Form, { form: form, loader: loader, onSubmit: submit, errorTitle: `Failed to ${isIndexed ? 'index' : 'deindex'} records`, children: [_jsxs("div", { className: cx(inputWrapper, clsMb30), children: [_jsx(FieldInput, { className: clsInput, contentClassName: clsInput, inputClassName: clsInput, type: "file", accept: ".csv", onChange: (event) => {
                            var _a;
                            form.fields.file.handleValueChange((_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0]);
                        } }), _jsx("div", { className: clsControls, children: _jsx(ButtonSubmit, { text: `Submit csv for ${isIndexed ? 'index' : 'deindex'}`, intent: isIndexed ? undefined : Intent.DANGER, fill: true }) })] }), _jsx(Button, { onClick: () => {
                    void downloadRules();
                }, icon: "cloud-download", children: "Download CSV" })] }));
});
