import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@blueprintjs/core';
import { css } from 'linaria';
import { noop } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Card } from 'common/components/card';
import { FieldInput } from 'common/components/fields/field-input';
import { Form } from 'common/components/form/form';
import { useStore } from 'common/hooks/use-store';
import { clsMb30 } from 'common/styles/margin-padding';
import { BlockUserFormStore } from './store';
const clsButton = css `
  & + & {
    margin-left: 8px;
  }
`;
export const BlockUserForm = observer(() => {
    const { loader, blockUser, unblockUser, form } = useStore(() => new BlockUserFormStore());
    return (_jsx(Card, { children: _jsxs(Form, { form: form, loader: loader, onSubmit: noop, errorTitle: "Failed to block user", children: [_jsx("div", { className: clsMb30, children: _jsx(FieldInput, { field: form.fields.userId, label: "User id", placeholder: "e.g. 102ea21f92db5084836ada740d19c295dl3" }) }), _jsx(Button, { className: clsButton, onClick: blockUser, intent: "danger", text: "Block", large: true }), _jsx(Button, { className: clsButton, onClick: unblockUser, intent: "primary", text: "Unblock", large: true })] }) }));
});
