import { jsx as _jsx } from "react/jsx-runtime";
import { Classes } from '@blueprintjs/core';
import { css, cx } from 'linaria';
const clsItem = css `
  display: flex;
  align-items: center;
  height: 50px;
  cursor: pointer;
  font-size: 16px;
  :hover {
    color: #4db690;
  }
`;
const clsText = css `
  display: inline-block;
  padding-right: 10px;
  padding-left: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const PopoverMenuItem = ({ children, onClick, className }) => (_jsx("div", { className: cx(className, clsItem, Classes.POPOVER_DISMISS), onClick: onClick, children: _jsx("div", { className: clsText, children: children }) }));
