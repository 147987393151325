import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Classes, Switch } from '@blueprintjs/core';
import { oneis, oneof } from '@whisklabs/grpc';
import { isDefined } from '@whisklabs/typeguards';
import { css, cx } from 'linaria';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { FieldCountries } from 'b2b/countries';
import { FieldPublisher, stubPublisher } from 'b2b/publishers';
import { NonEmptyString } from 'common/components/non-empty-string';
import { RowActions, TableCell, TableRow } from 'common/components/table-view';
import { useStore } from 'common/hooks/use-store';
import { clsMb10, clsMb5 } from 'common/styles/margin-padding';
import { textTruncate } from 'common/styles/text';
import { AppPublisherFieldLicence } from './field-licence';
import { AppPublisherStore, DEFAULT_PUBLISHER_LICENCE } from './store';
const clsNameCell = css `
  min-height: 40px;
`;
const clsText = css `
  word-break: break-word;
`;
const clsGlobalRegionSwitch = css `
  line-height: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`;
export const AppPublisherRow = observer(({ appPublishersStore, countriesStore, rowKind, datum, getRowProps, getCellProps }) => {
    const { form, add, addLoader, save, saveLoader, remove, removeLoader } = useStore(() => new AppPublisherStore(appPublishersStore));
    useEffect(() => {
        form.setOnSubmit(action(({ publisher, globalRegion, countries, ...values }) => {
            const data = {
                ...publisher,
                ...values,
                availableCountries: globalRegion
                    ? { oneof: 'global', value: {} }
                    : { oneof: 'countryList', value: { countries } },
                // legacy, required field
                countries: [],
            };
            if (rowKind === 'new') {
                void add(data);
            }
            else {
                void save(data);
            }
        }));
    }, [rowKind, form, save, add]);
    useEffect(() => {
        var _a, _b, _c, _d;
        form.reset({
            publisher: isDefined(datum)
                ? {
                    id: datum.id,
                    name: (_a = datum.name) !== null && _a !== void 0 ? _a : '',
                    domain: (_b = datum.domain) !== null && _b !== void 0 ? _b : '',
                }
                : stubPublisher(),
            licence: (_c = datum === null || datum === void 0 ? void 0 : datum.licence) !== null && _c !== void 0 ? _c : DEFAULT_PUBLISHER_LICENCE,
            globalRegion: isDefined(datum) ? oneis(datum.availableCountries, 'global') : true,
            countries: (_d = oneof(datum === null || datum === void 0 ? void 0 : datum.availableCountries, 'countryList', (countryList) => { var _a; return (_a = countryList === null || countryList === void 0 ? void 0 : countryList.countries) !== null && _a !== void 0 ? _a : []; })) !== null && _d !== void 0 ? _d : [],
        });
    }, [form, datum]);
    return (_jsx(_Fragment, { children: _jsxs(TableRow, { ...getRowProps(), children: [_jsx(TableCell, { ...getCellProps('publisher'), children: rowKind === 'new' ? (_jsx(FieldPublisher, { field: form.fields.publisher })) : (_jsxs("div", { className: cx(clsNameCell), children: [_jsx("div", { className: cx(clsText, clsMb10), children: _jsx(NonEmptyString, { value: form.values.publisher.name }) }), _jsxs("div", { className: cx(clsText, clsMb5, Classes.TEXT_SMALL, Classes.TEXT_MUTED), children: ["Domain: ", _jsx(NonEmptyString, { value: form.values.publisher.domain })] }), _jsxs("div", { className: cx(clsText, Classes.TEXT_SMALL, Classes.TEXT_MUTED), children: ["ID: ", _jsx(NonEmptyString, { value: form.values.publisher.id })] })] })) }), _jsxs(TableCell, { ...getCellProps('countries'), children: [_jsx(Switch, { className: clsGlobalRegionSwitch, labelElement: _jsx("span", { className: textTruncate, children: "\uD83C\uDF0E All countries" }), checked: form.values.globalRegion, onChange: form.fields.globalRegion.handleChecked, large: true }), !form.values.globalRegion ? (_jsx(FieldCountries, { field: form.fields.countries, countries: countriesStore.countries, tagColumns: 2 })) : null] }), _jsx(TableCell, { ...getCellProps('licence'), children: _jsx(AppPublisherFieldLicence, { field: form.fields.licence }) }), _jsx(TableCell, { ...getCellProps('action'), children: _jsx(RowActions, { rowKind: rowKind, form: form, addLoader: addLoader, saveLoader: saveLoader, removeLoader: removeLoader, onRemove: () => (isDefined(datum) ? remove(datum) : undefined) }) })] }) }));
});
