import { jsx as _jsx } from "react/jsx-runtime";
import { css } from 'linaria';
const clsNoData = css `
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const clsNoDataTitle = css `
  font-size: 28px;
`;
export const NoData = () => (_jsx("div", { className: clsNoData, children: _jsx("h4", { className: clsNoDataTitle, children: "No data" }) }));
