import { jsx as _jsx } from "react/jsx-runtime";
import { FormGroup } from '@blueprintjs/core';
import { DateRangeInput } from '@blueprintjs/datetime';
import dayjs from 'dayjs';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { getFieldFormGroupProps } from 'common/components/form/helpers';
import { DATE_FORMAT_NUMERIC } from 'common/config';
import { formatDate, parseDate } from 'common/helpers/date';
import { sharedPopoverProps } from 'common/popover';
const dayPickerContainer = css `
  height: 100%;
  &:first-child {
    padding-right: 10px;
    border-right: 1px solid #eee;
  }
  &:last-child {
    padding-left: 10px;
  }
`;
const dayPickerProps = {
    className: dayPickerContainer,
    showOutsideDays: true,
};
const minDate = dayjs().startOf('year').subtract(20, 'years').toDate();
const maxDate = dayjs().endOf('year').add(20, 'years').toDate();
const startDateInputProps = { large: true, placeholder: 'e.g. 20/01/2021' };
const endDateInputProps = { large: true, placeholder: 'e.g. 21/01/2021' };
const popoverProps = {
    ...sharedPopoverProps,
    fill: false,
};
const dateParse = (dateString) => new Date(parseDate(dateString, DATE_FORMAT_NUMERIC));
const dateFormat = (date) => formatDate(date, DATE_FORMAT_NUMERIC);
export const CampaignFormFieldDateRange = observer(({ field, label }) => (_jsx(FormGroup, { label: label, ...getFieldFormGroupProps(field), children: _jsx(DateRangeInput, { formatDate: dateFormat, parseDate: dateParse, value: field.value, onChange: field.handleValueChange, startInputProps: startDateInputProps, endInputProps: endDateInputProps, popoverProps: popoverProps, dayPickerProps: dayPickerProps, contiguousCalendarMonths: false, shortcuts: false, closeOnSelection: true, selectAllOnFocus: true, minDate: minDate, maxDate: maxDate }) })));
