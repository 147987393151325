import { jsx as _jsx } from "react/jsx-runtime";
import { isUndefined } from '@whisklabs/typeguards';
import { css, cx } from 'linaria';
import { memo } from 'react';
import { CountryFlagImage } from './country-flag-image';
import { getFlagEmoji, isFlagEmojiSupported } from './flag-emjoi';
const clsFlag = css `
  display: inline-block;
  vertical-align: text-bottom;
  line-height: 1;
`;
const clsEmoji = css `
  font-size: 1.3em;
  vertical-align: text-bottom;
`;
export const CountryFlag = memo(({ code, className }) => {
    if (isUndefined(code)) {
        return null;
    }
    return (_jsx("span", { className: cx(clsFlag, className), children: isFlagEmojiSupported() ? (_jsx("span", { className: clsEmoji, children: getFlagEmoji(code) })) : (_jsx(CountryFlagImage, { code: code })) }));
});
