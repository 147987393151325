import { jsx as _jsx } from "react/jsx-runtime";
import { Icon } from '@blueprintjs/core';
import { css } from 'linaria';
import { matchSorter } from 'match-sorter';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { Img, Link } from 'b2c/components';
import { recipePath } from 'b2c/helpers/routes';
import { Table } from 'common/components/client-pagination-table';
import { NonEmptyString } from 'common/components/non-empty-string';
import { COLORS } from 'common/helpers/colors';
import { createSearchFunction } from 'common/helpers/search';
import { boolCompareAsc, stringCompareAsc } from 'common/helpers/sort';
import { clsLink } from 'common/styles/link';
import { Actions } from './actions';
const clsTable = css `
  word-break: break-all;
`;
const searchFunction = createSearchFunction([
    (image) => image.recipeId,
    { key: (image) => image.recipeId, threshold: matchSorter.rankings.EQUAL },
]);
export const FlaggedImagesTable = observer(({ store }) => {
    const columns = useMemo(() => [
        {
            id: 'isTrustedUser',
            title: (_jsx("div", { title: "Trusted User", children: _jsx(Icon, { icon: 'endorsed', color: COLORS.GREEN }) })),
            width: '20px',
            sorter: (a, b) => boolCompareAsc(a.isFromTrustedUser, b.isFromTrustedUser),
            render: (recipe) => (_jsx("div", { children: recipe.isFromTrustedUser ? (_jsx(Icon, { icon: 'endorsed', color: COLORS.GREEN })) : (_jsx(Icon, { icon: 'ban-circle', color: COLORS.RED })) })),
        },
        {
            id: 'recipeId',
            title: 'Recipe Id',
            sorter: (a, b) => stringCompareAsc(a.recipeId, b.recipeId),
            render: (image) => (_jsx("a", { className: clsLink, href: recipePath(image.recipeId), rel: "noreferrer", target: "_blank", children: _jsx(NonEmptyString, { value: image.recipeId }) })),
        },
        {
            id: 'imageUrl',
            title: 'Image Url',
            sorter: (a, b) => stringCompareAsc(a.url, b.url),
            render: (image) => (_jsx("div", { children: _jsx(Link, { title: "Image Url", small: true, link: image.url }) })),
        },
        {
            id: 'imagePreview',
            title: 'Preview',
            sorter: (a, b) => stringCompareAsc(a.url, b.url),
            render: (image) => _jsx(Img, { imageUrl: image.url, position: "left" }),
        },
        {
            id: 'actions',
            width: '140px',
            title: 'Actions',
            align: 'center',
            render: (image) => _jsx(Actions, { image: image, onApprove: store.approveImage, onReject: store.rejectImage }),
        },
    ], [store.approveImage, store.rejectImage]);
    return (_jsx(Table, { loader: store.loader, tableClassName: clsTable, cellPaddingVertical: 10, data: store.images, withUnstableHeight: true, columns: columns, rowKey: "entityId", searchFunction: searchFunction }));
});
