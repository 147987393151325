import { css } from 'linaria';
export const clsHead = css `
  display: table-cell;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 600;
  background-color: #f6f6f9;
  color: #333;
  position: sticky;
  top: 0;
  z-index: 10;
  user-select: none;
  white-space: nowrap;
`;
export const clsSortable = css `
  cursor: pointer;
  user-select: none;
`;
export const clsFlex = css `
  display: flex;
  align-items: center;
`;
export const clsArrow = css `
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  margin-left: 16px;

  svg {
    width: 16px;
    height: 16px;
  }
`;
export const clsSortIconContainer = css `
  padding-left: 10px;
`;
export const clsAlignRight = css `
  justify-content: flex-end;
`;
export const clsAlignCenter = css `
  justify-content: center;
`;
export const clsAlignLeft = css `
  justify-content: flex-start;
`;
export const headClasses = {
    right: clsAlignRight,
    left: clsAlignLeft,
    center: clsAlignCenter,
};
