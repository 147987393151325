import { jsx as _jsx } from "react/jsx-runtime";
import { isText } from '@whisklabs/typeguards';
import { css, cx } from 'linaria';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
const clsImgHidden = css ``;
const clsImg = css `
  display: inline-block;
  vertical-align: middle;
  width: ${4 / 3}em;
  height: 1em;
  &.${clsImgHidden} {
    /* keep image placeholder to avoid text shifting */
    visibility: hidden;
  }
`;
export const CountryFlagImage = memo(({ code }) => {
    const [hasError, setHasError] = useState(false);
    const href = useMemo(() => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            const imageModule = require(`./country-flags/${code.toLowerCase()}.png`);
            return imageModule.default;
        }
        catch (_a) {
            /**/
        }
    }, [code]);
    useEffect(() => {
        setHasError(false);
    }, [code]);
    const handleError = useCallback(() => {
        setHasError(true);
    }, []);
    return (_jsx("img", { src: href, "aria-hidden": true, alt: code, className: cx(clsImg, hasError || !isText(href) ? clsImgHidden : undefined), onError: handleError }));
});
