import { jsx as _jsx } from "react/jsx-runtime";
import { Colors, Popover, Position } from '@blueprintjs/core';
import { css, cx } from 'linaria';
import { sharedPopoverProps } from 'common/popover';
export const clsSelectContainer = css `
  position: relative;
  cursor: pointer;
`;
export const clsOptionsContainer = css `
  width: 100%;
  max-height: 40vh;
  overflow: auto;
  z-index: 2;
  background: ${Colors.WHITE};
  box-shadow: 0 2px 11px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
`;
export const PopoverMenu = ({ targetClassName, popoverClassName, ...popoverProps }) => (_jsx(Popover, { ...sharedPopoverProps, position: Position.BOTTOM_RIGHT, wrapperTagName: "div", targetTagName: "div", targetClassName: cx(clsSelectContainer, targetClassName), popoverClassName: cx(clsOptionsContainer, popoverClassName), ...popoverProps }));
