import { css } from 'linaria';
import { COLORS } from 'common/helpers/colors';
export const clsLink = css `
  color: ${COLORS.GREEN};
  transition: color 200ms ease-out;
  & svg {
    fill: currentColor;
    transition: color 200ms ease-out;
  }
  &:hover {
    color: ${COLORS.GREEN_DARK};
  }
`;
export const clsNavLinkActive = css ``;
export const clsNavLink = css `
  color: #233748;
  transition: color 200ms ease-out;
  & svg {
    fill: #bdbdbd;
    transition: color 200ms ease-out;
  }
  &:hover {
    color: ${COLORS.GREEN};
    & svg {
      fill: ${COLORS.GREEN};
    }
  }
  &.${clsNavLinkActive} {
    color: ${COLORS.GREEN_DARK};
    & svg {
      fill: ${COLORS.GREEN_DARK};
    }
  }
`;
