import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Radio, RadioGroup } from '@blueprintjs/core';
import { css, cx } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { RuleType } from 'b2c/indexing-tool/types';
import { Card, CardTitle } from 'common/components/card';
import { clsFormMaxWidth } from 'common/components/form/styles';
import { clsFlex } from 'common/styles/layout';
import { clsMb20 } from 'common/styles/margin-padding';
import { IndexingToolContext } from '../context';
import { ActionsSelect } from './actions';
const clsWrapperGap = css `
  gap: 100px;
`;
export const Settings = observer(() => {
    const { controlStore: { ruleType, setRuleType, recordType, setRecordType }, } = useContext(IndexingToolContext);
    return (_jsx(Card, { header: _jsx(CardTitle, { children: "Settings" }), children: _jsxs("div", { className: cx(clsFormMaxWidth, clsFlex, clsWrapperGap), children: [_jsxs(RadioGroup, { className: clsMb20, label: "Rule type", selectedValue: ruleType, onChange: (event) => setRuleType(Number(event.currentTarget.value)), children: [_jsx(Radio, { label: "Deindex", value: RuleType.DIRECTORY_RULE_TYPE_NO_INDEX }, RuleType.DIRECTORY_RULE_TYPE_NO_INDEX), _jsx(Radio, { label: "Index", value: RuleType.DIRECTORY_RULE_TYPE_ALWAYS_INDEX }, RuleType.DIRECTORY_RULE_TYPE_ALWAYS_INDEX)] }), _jsx(ActionsSelect, { value: recordType, onClick: setRecordType })] }) }));
});
