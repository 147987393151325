import { jsx as _jsx } from "react/jsx-runtime";
import { Classes, MenuItem } from '@blueprintjs/core';
import { renderFilteredItems } from '@blueprintjs/select';
import { css, cx } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo } from 'react';
import { InfiniteScroll } from 'common/components/infinite-scroll';
const clsListContainer = css `
  max-height: 300px;
`;
export const InfiniteList = observer(({ store, limit = 20, itemListRendererProps, queryRequired = false }) => {
    const { query, filteredItems, itemsParentRef } = itemListRendererProps;
    const disabled = useMemo(() => queryRequired && query.trim().length === 0, [queryRequired, query]);
    const params = useMemo(() => ({ limit, query }), [limit, query]);
    useEffect(() => {
        if (!disabled) {
            void store.loadInitialData(params);
        }
    }, [store, params, disabled]);
    const handleLoadMore = useCallback(() => {
        void store.loadMoreData(params);
    }, [store, params]);
    if (disabled) {
        return null;
    }
    return (_jsx(InfiniteScroll, { loadMore: handleLoadMore, hasMore: store.hasMoreData, isEmpty: filteredItems.length === 0, emptyElement: _jsx(MenuItem, { text: "No results.", disabled: true }), isLoading: store.loader.isPending, className: cx(Classes.MENU, clsListContainer), ref: itemsParentRef, children: renderFilteredItems(itemListRendererProps) }));
});
