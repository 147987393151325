import { jsx as _jsx } from "react/jsx-runtime";
import { Button, Intent } from '@blueprintjs/core';
import { css } from 'linaria';
import React, { useCallback } from 'react';
const clsButton = css `
  text-align: center;

  & + & {
    margin-left: 30px;
  }
`;
export const Actions = ({ user, onReject }) => {
    const handleReject = useCallback(() => {
        onReject(user);
    }, [user, onReject]);
    return (_jsx("div", { children: _jsx(Button, { className: clsButton, rightIcon: "cross", onClick: handleReject, large: true, intent: Intent.DANGER }) }));
};
