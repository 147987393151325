import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Intent } from '@blueprintjs/core';
import { css, cx } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { RuleType } from 'b2c/indexing-tool/types';
import { FieldInput } from 'common/components/fields/field-input';
import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { clsMb30 } from 'common/styles/margin-padding';
import { IndexingToolContext } from '../../context';
const inputWrapper = css `
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;
const clsInput = css `
  flex: 2 1 auto;
`;
const clsControls = css `
  flex: 1 0 min-content;
  display: flex;
  margin-top: 10px;
`;
export const FieldForm = observer(() => {
    const { fieldStore: { form, loader, submit }, controlStore: { ruleType }, } = useContext(IndexingToolContext);
    const isIndexed = ruleType === RuleType.DIRECTORY_RULE_TYPE_ALWAYS_INDEX;
    return (_jsx(Form, { form: form, loader: loader, onSubmit: submit, errorTitle: `Failed to ${isIndexed ? 'index' : 'deindex'} record`, children: _jsxs("div", { className: cx(inputWrapper, clsMb30), children: [_jsx(FieldInput, { className: clsInput, field: form.fields.data, label: "Add URL or ID", placeholder: "e.g. 101cb436a7b9b65b20b62b08ee190eb63bd1835f9fb" }), _jsx("div", { className: clsControls, children: _jsx(ButtonSubmit, { text: isIndexed ? 'Index' : 'Deindex', intent: isIndexed ? undefined : Intent.DANGER, fill: true }) })] }) }));
});
