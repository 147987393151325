import { jsx as _jsx } from "react/jsx-runtime";
import { css } from 'linaria';
import { matchSorter } from 'match-sorter';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { profilePath } from 'b2c/helpers/routes';
import { Table } from 'common/components/client-pagination-table';
import { NonEmptyString } from 'common/components/non-empty-string';
import { createSearchFunction } from 'common/helpers/search';
import { stringCompareAsc } from 'common/helpers/sort';
import { clsLink } from 'common/styles/link';
import { Actions } from './actions';
const clsTable = css `
  word-break: break-all;
`;
const searchFunction = createSearchFunction([(user) => user.userId, { key: (user) => [user.userId, user.username] }], { threshold: matchSorter.rankings.CONTAINS });
export const UserListTable = observer(({ store }) => {
    const columns = useMemo(() => [
        {
            id: 'userId',
            title: 'User Id',
            width: '344px',
            sorter: (a, b) => stringCompareAsc(a.userId, b.userId),
            render: (recipe) => (_jsx("a", { className: clsLink, href: profilePath(recipe.userId), rel: "noreferrer", target: "_blank", children: _jsx(NonEmptyString, { value: recipe.userId }) })),
        },
        {
            id: 'username',
            title: 'User Name',
            sorter: (a, b) => stringCompareAsc(a.username, b.username),
            render: (recipe) => _jsx(NonEmptyString, { value: recipe.username }),
        },
        {
            id: 'actions',
            width: '140px',
            title: 'Actions',
            align: 'center',
            render: (user) => _jsx(Actions, { user: user, onReject: store.rejectRecipeText }),
        },
    ], [store.rejectRecipeText]);
    return (_jsx(Table, { loader: store.loader, tableClassName: clsTable, cellPaddingVertical: 10, data: store.listUsers, withUnstableHeight: true, columns: columns, rowKey: "userId", searchFunction: searchFunction }));
});
