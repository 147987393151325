import { jsx as _jsx } from "react/jsx-runtime";
import { css, cx } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useDocumentTitle } from 'common/hooks/use-document-title';
import { clsNoMargin } from 'common/styles/margin-padding';
const clsPageTitle = css `
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  color: #233748;
  word-break: break-all;
`;
export const PageTitle = observer(({ title }) => {
    useDocumentTitle(title);
    return _jsx("h1", { className: cx(clsPageTitle, clsNoMargin), children: title });
});
