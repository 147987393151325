import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isText } from '@whisklabs/typeguards';
import { css, cx } from 'linaria';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { FieldCheckbox } from 'common/components/fields/field-checkbox';
import { FieldInput } from 'common/components/fields/field-input';
import { FieldTextArea } from 'common/components/fields/field-textarea';
import { clsRelativeContainer } from 'common/styles/layout';
import { clsLink } from 'common/styles/link';
import { clsMb30 } from 'common/styles/margin-padding';
const clsLinkOutside = css `
  position: absolute;
  left: 100%;
  top: 35px;
  margin-left: 10px;
  white-space: nowrap;
`;
export const AppInfoFormFields = observer(({ store }) => {
    const { form } = store;
    return (_jsxs("div", { className: clsMb30, children: [_jsx(FieldInput, { field: form.fields.name, label: "Name", maxLength: 255 }), _jsx(FieldTextArea, { field: form.fields.description, label: "Description", maxLength: 1000 }), _jsxs("div", { className: clsRelativeContainer, children: [_jsx(FieldInput, { field: form.fields.businessId, label: "Business ID", maxLength: 255, placeholder: "e.g. 8dfd1e8a-839d-4f66-90fe-e4ddf04746c4" }), form.fields.businessId.isValid && isText(form.fields.businessId.value.trim()) ? (_jsx(Link, { to: `/b2b/businesses/${form.fields.businessId.value}`, className: cx(clsLink, clsLinkOutside), children: "Go to business" })) : null] }), _jsx(FieldInput, { field: form.fields.trackingId, label: "Tracking ID", maxLength: 1000 }), _jsxs("div", { children: [_jsx(FieldCheckbox, { field: form.fields.isTest, label: "Test", inline: true }), _jsx(FieldCheckbox, { field: form.fields.isTrial, label: "Sandbox", inline: true })] })] }));
});
