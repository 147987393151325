import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { NonIdealState, Spinner } from '@blueprintjs/core';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { ErrorMessage } from 'common/components/error-message';
import { LoadStatus } from 'common/helpers/data-loader';
const clsEmpty = css `
  margin-top: 30px;
  height: auto !important;
`;
export const LoadingWrapper = observer(({ loader, status = loader === null || loader === void 0 ? void 0 : loader.status, pending = status === LoadStatus.idle || status === LoadStatus.pending, fail = status === LoadStatus.fail, error = loader === null || loader === void 0 ? void 0 : loader.error, empty, pendingProps, emptyProps, errorProps, children, }) => {
    if (pending) {
        return _jsx(Spinner, { ...pendingProps });
    }
    if (fail) {
        return _jsx(ErrorMessage, { title: "Failed to load data", error: error, ...errorProps });
    }
    if (empty) {
        return _jsx(NonIdealState, { icon: "search", title: "No data found", className: clsEmpty, ...emptyProps });
    }
    return _jsx(_Fragment, { children: children });
});
