import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Classes, Intent, Popover, Position } from '@blueprintjs/core';
import { isText } from '@whisklabs/typeguards';
import { css, cx } from 'linaria';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { FieldInput } from 'common/components/fields/field-input';
import { useClipboard } from './use-clipboard';
import { useSelectAllInput } from './use-select-all';
const clsField = css `
  display: flex;
  flex: 1 1 auto;
  align-items: center !important;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`;
const clsFormGroup = css `
  flex: 1 1 auto;
  display: flex;
  align-items: center;

  & .${Classes.LABEL} {
    width: 75px;
    flex: 0 0 auto;
    margin-bottom: 0;
  }

  & .${Classes.INPUT} {
    cursor: text !important;
  }
`;
const clsFormContent = css `
  flex-grow: 1;
`;
const clsButtonCopy = css `
  margin-left: 15px;
`;
const clsCopied = css `
  .${Classes.POPOVER_CONTENT} {
    padding: 4px 8px;
  }
`;
export const AppCredentialsField = memo(({ value, label, isHidden }) => {
    const [isSuccessShown, setIsSuccessShown] = useState(false);
    const copyRef = useRef(null);
    const handleCopied = useCallback(() => {
        setIsSuccessShown(true);
    }, []);
    useClipboard(copyRef, value, handleCopied);
    // Hide tooltip after timeout
    useEffect(() => {
        if (isSuccessShown) {
            const timeoutId = setTimeout(() => {
                setIsSuccessShown(false);
            }, 1000);
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [isSuccessShown]);
    const selectAllHandlers = useSelectAllInput();
    return (_jsxs("div", { className: clsField, children: [_jsx(FieldInput, { label: label, className: clsFormGroup, contentClassName: clsFormContent, value: isHidden && isText(value) ? '•'.repeat(value.length) : value, inputClassName: Classes.DISABLED, inline: true, fill: true, noMargin: true, disabled: isHidden, readOnly: !isHidden, ...selectAllHandlers }), _jsx(Popover, { targetTagName: "div", wrapperTagName: "div", boundary: "viewport", position: Position.TOP, content: _jsx("div", { children: "Copied" }), isOpen: isSuccessShown, popoverClassName: cx(clsCopied, Classes.DARK), children: _jsx(Button, { title: "Copy to clipboard", text: "Copy", icon: "duplicate", elementRef: copyRef, intent: Intent.PRIMARY, className: clsButtonCopy, large: true }) })] }));
});
