import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Intent } from '@blueprintjs/core';
import { css } from 'linaria';
import React, { useCallback } from 'react';
const clsButton = css `
  text-align: center;

  & + & {
    margin-left: 30px;
  }
`;
export const Actions = ({ image, onApprove, onReject }) => {
    const handleApprove = useCallback(() => {
        onApprove(image);
    }, [image, onApprove]);
    const handleReject = useCallback(() => {
        onReject(image);
    }, [image, onReject]);
    return (_jsxs("div", { children: [_jsx(Button, { className: clsButton, rightIcon: "tick", onClick: handleApprove, large: true, intent: Intent.SUCCESS }), _jsx(Button, { className: clsButton, rightIcon: "cross", onClick: handleReject, large: true, intent: Intent.DANGER })] }));
};
