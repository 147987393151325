import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Tab, Tabs } from '@blueprintjs/core';
import { isPresent } from '@whisklabs/typeguards';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { Link, matchPath, useRouteMatch } from 'react-router-dom';
import { DocumentTitle } from 'common/components/document-title';
import { RouteSwitch } from 'common/components/routes';
import { join } from 'common/helpers/router';
import { useRandomId } from 'common/hooks/use-random-id';
import { getStore } from 'common/stores';
import { clsMb40 } from 'common/styles/margin-padding';
const clsTabLink = css `
  font-size: 20px;
`;
const doesParentMatch = (pathname, parentPath) => isPresent(matchPath(pathname, { path: parentPath, exact: true }));
export const RouterTabs = observer(({ tabs: tabsProp, defaultPath: defaultPathProp, defaultGuard }) => {
    var _a;
    const { pathname } = getStore('router');
    const { path: parentPath, url: parentUrl } = useRouteMatch();
    const tabs = useMemo(() => tabsProp.filter(isPresent), [tabsProp]);
    const defaultPath = defaultPathProp !== null && defaultPathProp !== void 0 ? defaultPathProp : (_a = tabs[0]) === null || _a === void 0 ? void 0 : _a.path;
    const selectedTabPath = useMemo(() => {
        var _a, _b;
        const matchedTab = tabs.find((tab) => matchPath(pathname, join(parentPath, tab.path)));
        return (_b = (_a = matchedTab === null || matchedTab === void 0 ? void 0 : matchedTab.path) !== null && _a !== void 0 ? _a : (doesParentMatch(pathname, parentPath) ? defaultPath : undefined)) !== null && _b !== void 0 ? _b : undefined;
    }, [tabs, pathname, parentPath, defaultPath]);
    const randomId = useRandomId();
    if (tabs.length === 0) {
        return null;
    }
    // 404 support
    const INVALID_TAB_ID = '$invalid$';
    return (_jsxs(_Fragment, { children: [_jsx(Tabs, { selectedTabId: selectedTabPath !== null && selectedTabPath !== void 0 ? selectedTabPath : INVALID_TAB_ID, className: clsMb40, id: randomId, large: true, children: tabs.map((tab) => (_jsx(Tab, { id: tab.path, children: _jsx(Link, { to: join(parentUrl, tab.path), className: clsTabLink, children: tab.label }) }, tab.path))) }), _jsx(RouteSwitch, { defaultGuard: defaultGuard, defaultPath: defaultPath, routes: tabs.map((tab) => ({
                    path: tab.path,
                    render: () => {
                        const TabComponent = tab.component;
                        return (_jsxs(_Fragment, { children: [_jsx(DocumentTitle, { title: tab.label }), _jsx(TabComponent, {})] }));
                    },
                })) })] }));
});
