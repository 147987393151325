import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Intent, Tag } from '@blueprintjs/core';
import { isPresent } from '@whisklabs/typeguards';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Info, Link, User } from 'b2c/components';
import { communityPath, recipePath } from 'b2c/helpers/routes';
import { Box } from 'common/components/box';
import { ActionsSelect } from './actions';
const clsRow = css `
  &:hover {
    background-color: #f5f8fa;
  }
`;
export const Row = observer(({ recipe, store }) => {
    var _a, _b;
    const [full, setFull] = useState(false);
    return (_jsxs(Box, { display: "block", border: "1px solid #DDD", borderRadius: "18px", p: 4, mb: 4, className: clsRow, children: [_jsxs(Box, { alignItems: "stretch", children: [_jsxs(Box, { flexGrow: 1, flexDirection: "row", children: [_jsxs(Box, { flexDirection: "column", width: "50%", alignItems: "flex-start", children: [_jsx(Info, { type: "Recipe", text: _jsx(Link, { title: recipe.name, small: true, link: recipePath(recipe.id) }) }), _jsx(Info, { type: "Email", text: recipe.email })] }), _jsxs(Box, { flexDirection: "column", width: "50%", alignItems: "flex-start", children: [_jsx(Info, { type: "Community", text: isPresent(recipe.community) ? (_jsx(Link, { title: recipe.community.name, small: true, link: communityPath(recipe.community.id) })) : null }), _jsx(Info, { type: "Comment", text: recipe.comment })] })] }), _jsxs(Box, { flexDirection: "column", alignItems: "flex-end", mr: 4, width: "110px", justifyContent: "space-between", children: [_jsx(Box, { mb: 4, children: _jsx(Tag, { large: true, intent: Intent.PRIMARY, title: recipe.flagReason, children: recipe.flagReason }) }), _jsx(Tag, { rightIcon: full ? 'double-chevron-up' : 'double-chevron-down', large: true, interactive: true, onClick: () => setFull((i) => !i), children: full ? 'Less' : 'More' })] }), _jsx(Box, { width: "36px", children: _jsx(ActionsSelect, { onClick: store.applyAction, recipe: recipe }) })] }), full ? (_jsx(Box, { mt: 4, children: _jsx(User, { type: "Reporter", ...recipe.reporter, id: (_b = (_a = recipe.reporter) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '' }) })) : null] }));
});
