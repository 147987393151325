import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Tag } from '@blueprintjs/core';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { Box } from 'common/components/box';
import { getStore } from 'common/stores';
const clsInfo = css `
  background: white;
`;
const clsInfoType = css `
  color: #555;
  font-weight: 700;
`;
const clsInfoData = css `
  word-break: break-all;
`;
const lines = 3;
const clsInfoText = css `
  display: flex;
  -webkit-line-clamp: ${lines};
  line-clamp: ${lines};
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
`;
const clsInfoTextFull = css `
  display: block;
`;
export const Info = observer(({ type, text }) => {
    const [more, setMore] = useState(false);
    const [full, setFull] = useState(false);
    const ref = useRef(null);
    const { currentWidth } = getStore('size');
    useEffect(() => {
        setFull(false);
        setMore(false);
    }, [text]);
    useEffect(() => {
        requestAnimationFrame(() => {
            if (ref.current) {
                const sh = ref.current.scrollHeight;
                setMore(sh > 20 * lines /* optimize for resize */ || sh > ref.current.clientHeight);
            }
        });
    }, [text, currentWidth]);
    return (_jsxs(Box, { className: clsInfo, display: "inline-flex", alignItems: "stretch", p: 4, m: 2, borderRadius: "12px", children: [_jsxs(Box, { pr: 4, flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between", children: [_jsxs(Box, { className: clsInfoType, children: [type, ":"] }), more ? (_jsx(Tag, { rightIcon: full ? 'chevron-up' : 'chevron-down', fill: true, minimal: true, interactive: true, onClick: () => setFull((i) => !i), children: full ? 'Less' : 'More' })) : null] }), _jsx(Box, { className: clsInfoData, flexDirection: "column", alignItems: "flex-start", pl: 4, children: _jsx("div", { ref: ref, className: `${clsInfoText} ${full ? clsInfoTextFull : ''}`, children: text !== null && text !== void 0 ? text : '-----' }) })] }));
});
