import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Box } from 'common/components/box';
import { InfiniteScroll } from 'common/components/infinite-scroll';
import { Search } from 'common/components/search';
import { AddUrlForm } from './add-url-form';
import { Row } from './row';
const clsScroller = css `
  && {
    width: 100%;
    padding: 1px;
  }
`;
const clsHeader = css `
  position: sticky;
  z-index: 10;
  padding-bottom: 10px;
  background-color: white;
  top: 0;
`;
export const Table = observer(({ store }) => {
    useEffect(() => {
        store.loadInitialData();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: clsHeader, children: [_jsx(AddUrlForm, { store: store }), _jsx(Box, { alignItems: "flex-start", mb: 20, children: _jsx(Box, { flexGrow: 1, children: _jsx(Search, { search: store.searchText, placeholder: "Filter by any field", onChange: store.search }) }) })] }), _jsx(InfiniteScroll, { className: clsScroller, loadMore: store.loadMoreData, hasMore: store.hasMoreData, isEmpty: store.domainsFiltered.length === 0, emptyElement: _jsx("div", { children: "No items" }), isLoading: store.loader.isPending, useScrollContentAsRoot: false, children: store.domainsFiltered.map((item) => (_jsx(Row, { domain: item, store: store }, item.id))) })] }));
});
