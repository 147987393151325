import { css } from 'linaria';
import { clsTd } from '../table-body/cell/styles';
const clsRowDefault = css ``;
const clsRowNew = css ``;
export const clsRow = css `
  cursor: default;
  display: table-row;
  transition: background-color 200ms ease-out;
  &.${clsRowNew} > .${clsTd} {
    background-color: rgba(61, 199, 149, 0.13);
  }
  &:hover {
    &.${clsRowDefault} > .${clsTd} {
      background-color: #f5f8fa;
    }
    &.${clsRowNew} > .${clsTd} {
      background-color: rgba(61, 199, 149, 0.16);
    }
  }
`;
export const rowClasses = {
    default: clsRowDefault,
    new: clsRowNew,
};
