import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isDefined } from '@whisklabs/typeguards';
import { css, cx } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { BusinessInvitationsPage } from 'b2b/businesses/details/invitations';
import { BusinessUsersPage } from 'b2b/businesses/details/users';
import { LoadingWrapper } from 'common/components/loading-wrapper';
import { PageHeader, PageTitle } from 'common/components/page-header';
import { RouterTabs } from 'common/components/router-tabs';
import { DATE_FORMAT_TEXT_SHORT } from 'common/config';
import { formatDate } from 'common/helpers/date';
import { fromTimestampToMillis } from 'common/helpers/grpc';
import { useStore } from 'common/hooks/use-store';
import { clsFlex, clsFlexAlignItemsCenter } from 'common/styles/layout';
import { BusinessActivationStatus } from '../common/activation-status';
import { BusinessAccountPage } from './account';
import { BusinessActivationsPage } from './activations';
import { BusinessAppsPage } from './apps';
import { BusinessDetailsStoreContextProvider } from './context';
import { BusinessDetailsStore } from './store';
import { BusinessWidgetsPage } from './widgets';
const clsActivationStatus = css `
  margin-top: 2px;
`;
export const BusinessDetailsPage = observer(() => {
    var _a, _b;
    const store = useStore(() => new BusinessDetailsStore());
    const { loader, loadBusiness, business, error, isStatusActive } = store;
    const { businessId } = useParams();
    useEffect(() => {
        void loadBusiness({ id: businessId });
    }, [loadBusiness, businessId]);
    const tabs = useMemo(() => [
        { path: '/account', label: 'Account', component: BusinessAccountPage },
        { path: '/activations', label: 'Activations', component: BusinessActivationsPage },
        ...(isStatusActive(business.activationStatus)
            ? [
                { path: '/api-keys', label: 'API Keys', component: BusinessAppsPage },
                { path: '/users', label: 'Users', component: BusinessUsersPage },
                { path: '/invitations', label: 'Invitations', component: BusinessInvitationsPage },
                { path: '/widgets', label: 'Widgets', component: BusinessWidgetsPage },
            ]
            : []),
    ], [isStatusActive, business.activationStatus]);
    return (_jsx(BusinessDetailsStoreContextProvider, { value: store, children: _jsxs(LoadingWrapper, { loader: loader, error: error, children: [_jsx(PageHeader, { title: _jsxs("div", { className: cx(clsFlex, clsFlexAlignItemsCenter), children: [_jsx(PageTitle, { title: `Business "${(_b = (_a = business.details) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ''}"` }), _jsx(BusinessActivationStatus, { status: business.activationStatus, className: clsActivationStatus, standalone: true })] }), description: isDefined(business.termsAndConditionsTime)
                        ? `T&C accepted: ${formatDate(fromTimestampToMillis(business.termsAndConditionsTime), DATE_FORMAT_TEXT_SHORT)}`
                        : null }), _jsx(RouterTabs, { tabs: tabs })] }) }));
});
