import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonGroup, InputGroup } from '@blueprintjs/core';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { Box } from 'common/components/box';
import { Card } from 'common/components/card';
const clsCard = css `
  width: 100%;
  && {
    padding: 12px;
  }
`;
const clsActions = css `
  margin-left: auto;
`;
export const Row = observer(({ domain, store }) => {
    const [isEdit, setIsEdit] = useState(false);
    const handleSave = useCallback(async () => {
        await domain.editDomain();
        setIsEdit(false);
    }, [domain]);
    const handleDelete = useCallback(async () => {
        await domain.removeDomain();
        store.removeFromList(domain.id);
    }, [domain, store]);
    return (_jsx(Box, { mb: 12, children: _jsx(Card, { className: clsCard, children: _jsxs(Box, { alignItems: "center", children: [isEdit ? (_jsx(InputGroup, { value: domain.value, large: true, onChange: (ev) => domain.setValue(ev.currentTarget.value) })) : (_jsx("code", { className: "bp4-code-block", children: domain.value })), _jsxs(ButtonGroup, { className: clsActions, large: true, children: [!isEdit ? (_jsx(Button, { icon: "edit", disabled: domain.removeLoader.isPending, text: "Edit", onClick: () => setIsEdit(true) })) : (_jsx(Button, { icon: "tick", disabled: domain.removeLoader.isPending, loading: domain.editLoader.isPending, text: "Save", onClick: handleSave })), _jsx(Button, { icon: "trash", disabled: domain.editLoader.isPending, loading: domain.removeLoader.isPending, onClick: handleDelete, text: "Delete" })] })] }) }) }));
});
