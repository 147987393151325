import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Classes, Elevation } from '@blueprintjs/core';
import { isDefined } from '@whisklabs/typeguards';
import { css, cx } from 'linaria';
const clsCard = css `
  &.${Classes.CARD} {
    padding: 24px;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const clsHeader = css `
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;
export const Card = ({ children, header, elevation = Elevation.ZERO, interactive = false, className }) => (_jsxs("div", { className: cx(clsCard, Classes.CARD, Classes.elevationClass(elevation), interactive && Classes.INTERACTIVE, className), children: [isDefined(header) ? _jsx("div", { className: clsHeader, children: header }) : null, _jsx("div", { children: children })] }));
