import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@blueprintjs/core';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { Card } from 'common/components/card';
import { FieldInput } from 'common/components/fields/field-input';
import { Form } from 'common/components/form/form';
import { useStore } from 'common/hooks/use-store';
import { clsMb30 } from 'common/styles/margin-padding';
import { MergeUsersFormStore } from './store';
const clsInput = css `
  & + & {
    margin-top: 12px;
  }
`;
export const MergeUsersForm = observer(() => {
    const { loader, mergeUsers, form } = useStore(() => new MergeUsersFormStore());
    return (_jsx(Card, { children: _jsxs(Form, { form: form, loader: loader, onSubmit: mergeUsers, errorTitle: "Failed to merge user", children: [_jsxs("div", { className: clsMb30, children: [_jsx(FieldInput, { className: clsInput, field: form.fields.fromUserId, label: "From User id", placeholder: "e.g. 102ea21f92db5084836ada740d19c295dl3" }), _jsx(FieldInput, { className: clsInput, field: form.fields.toUserId, label: "To User id", placeholder: "e.g. 102ea21f92db5084836ada740d19c295dl4" })] }), _jsx(Button, { type: "submit", intent: "primary", text: "Merge", large: true })] }) }));
});
