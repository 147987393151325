import { Intent, Position, Toaster } from '@blueprintjs/core';
import { isObject, isString, isText } from '@whisklabs/typeguards';
import { css } from 'linaria';
import { createElement } from 'react';
import { BaseStore } from './base';
// TODO: bug in blueprintjs
const clsToaster = css `
  && {
    position: fixed;
  }
`;
export class ToastStore extends BaseStore {
    constructor({ timeout = 4000, errorTimeout = -1, ...toasterProps } = {}) {
        super();
        Object.defineProperty(this, "toaster", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "timeout", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "errorTimeout", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "storeReset", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.toaster.clear();
            }
        });
        Object.defineProperty(this, "show", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (props, key) => this.toaster.show({
                timeout: this.timeout,
                ...props,
            }, key)
        });
        Object.defineProperty(this, "success", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (propsOrMessage, key) => this.show({
                intent: Intent.SUCCESS,
                icon: 'tick',
                ...this.normalizePropsOrMessage(propsOrMessage),
            }, key)
        });
        Object.defineProperty(this, "warning", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (propsOrMessage, key) => this.show({
                intent: Intent.WARNING,
                icon: 'time',
                ...this.normalizePropsOrMessage(propsOrMessage),
            }, key)
        });
        Object.defineProperty(this, "error", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (propsOrMessage, error, key) => {
                const { message, ...props } = this.normalizePropsOrMessage(propsOrMessage);
                const errorMessage = isObject(error) ? error.message : undefined;
                return this.show({
                    intent: Intent.DANGER,
                    icon: 'error',
                    timeout: this.errorTimeout,
                    message: isText(errorMessage)
                        ? createElement('div', null, message, createElement('br'), createElement('br'), 'Error: ', errorMessage)
                        : message,
                    ...props,
                }, key);
            }
        });
        Object.defineProperty(this, "info", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (propsOrMessage, key) => this.show({
                intent: Intent.PRIMARY,
                icon: 'info-sign',
                ...this.normalizePropsOrMessage(propsOrMessage),
            }, key)
        });
        Object.defineProperty(this, "clear", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => this.toaster.clear()
        });
        Object.defineProperty(this, "normalizePropsOrMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (propsOrMessage) => isString(propsOrMessage) ? { message: propsOrMessage } : propsOrMessage
        });
        this.toaster = Toaster.create({
            position: Position.TOP,
            maxToasts: 2,
            usePortal: true,
            className: clsToaster,
            ...toasterProps,
        });
        this.timeout = timeout;
        this.errorTimeout = errorTimeout;
    }
}
