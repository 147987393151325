import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from '@blueprintjs/core';
import { css, cx } from 'linaria';
import { memo } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { join } from 'common/helpers/router';
import { clsNavLink, clsNavLinkActive } from 'common/styles/link';
const clsListItem = css `
  list-style-type: none;
  margin: 0;
  margin-bottom: 15px;
  padding: 0;
`;
const clsSidebarNavLink = css `
  display: flex;
  font-size: 18px;
  line-height: 1.5;
`;
const clsIcon = css `
  margin-right: 12px;
  margin-top: -2px;
`;
export const SidebarItem = memo(({ className, icon, label, to }) => {
    const { url } = useRouteMatch();
    return (_jsx("li", { className: cx(clsListItem, className), children: _jsxs(NavLink, { to: join(url, to), className: cx(clsNavLink, clsSidebarNavLink), activeClassName: clsNavLinkActive, children: [_jsx("span", { className: clsIcon, children: _jsx(Icon, { icon: icon, iconSize: 16, color: "#bdbdbd" }) }), _jsx("span", { children: label })] }) }));
});
