import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Classes, Colors, Icon, Intent, Popover, Position } from '@blueprintjs/core';
import { css, cx } from 'linaria';
import { memo } from 'react';
import { ButtonDelete } from 'common/components/buttons';
import { sharedPopoverProps } from 'common/popover';
import { clsFlex } from 'common/styles/layout';
const clsPopover = css `
  & .${Classes.POPOVER_CONTENT} {
    padding: 20px;
    max-width: 400px;
    background-color: ${Colors.LIGHT_GRAY4};
  }

  & .bp3-popover-arrow-fill {
    fill: ${Colors.LIGHT_GRAY4};
  }
`;
const clsBody = css `
  display: flex;
  align-items: flex-start;
`;
const clsIcon = css `
  margin-right: 20px;
`;
const clsContent = css `
  font-size: 16px;
  overflow: hidden;
  margin-bottom: 20px;
`;
const clsName = css `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const clsFooter = css `
  display: flex;
  margin-top: 10px;
`;
const clsActions = css `
  margin-left: auto;
`;
const clsAction = css `
  margin-left: 10px;
  &:first-child {
    margin-left: 0;
  }
`;
export const DeletePopover = memo(({ entityType, entityName, onConfirmDelete, isDeleting, className, large = false }) => {
    return (_jsx(Popover, { ...sharedPopoverProps, minimal: false, position: Position.LEFT, className: className, popoverClassName: clsPopover, content: _jsxs(_Fragment, { children: [_jsxs("div", { className: clsBody, children: [_jsx(Icon, { icon: "trash", iconSize: 40, intent: Intent.DANGER, className: clsIcon }), _jsxs("div", { className: clsContent, children: ["Are you sure you want to delete ", entityType, _jsx("br", {}), _jsxs("div", { className: clsFlex, children: [_jsx("strong", { className: clsName, title: entityName, children: entityName }), ' ', _jsx("span", { children: "?" })] })] })] }), _jsx("div", { className: clsFooter, children: _jsxs("div", { className: clsActions, children: [_jsx(Button, { className: cx(clsAction, Classes.POPOVER_DISMISS), large: true, children: "Cancel" }), _jsx(Button, { onClick: onConfirmDelete, intent: Intent.DANGER, className: clsAction, loading: isDeleting, large: true, children: "Delete" })] }) })] }), target: _jsx(ButtonDelete, { large: large }) }));
});
