import { jsx as _jsx } from "react/jsx-runtime";
import { MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { RecordType } from 'b2c/indexing-tool/types';
import { FieldInput } from 'common/components/fields/field-input';
import { sharedPopoverProps } from 'common/popover';
import { clsMb30 } from 'common/styles/margin-padding';
const clsActionItem = css `
  padding: 10px;
`;
const ActionSelect = Select.ofType();
const itemsMap = {
    [RecordType.DIRECTORY_RECORD_TYPE_COMMUNITY_RECIPE]: 'Recipes',
    [RecordType.DIRECTORY_RECORD_TYPE_COMMUNITY]: 'Communities',
    [RecordType.DIRECTORY_RECORD_TYPE_COMMUNITY_CONVERSATION]: 'Conversations',
    [RecordType.DIRECTORY_RECORD_TYPE_DISH]: 'Dish pages',
    [RecordType.DIRECTORY_RECORD_TYPE_FOODPEDIA_PRODUCT]: 'Ingredient pages',
    [RecordType.DIRECTORY_RECORD_TYPE_PROFILE]: 'Profiles',
};
const items = Object.entries(itemsMap).map(([id, text]) => ({ id: Number(id), text }));
const renderActionItem = (item, { handleClick, modifiers }) => (_jsx(MenuItem, { className: clsActionItem, active: modifiers.active, onClick: handleClick, text: item.text }, item.id));
export const ActionsSelect = observer(({ value, onClick }) => {
    const handleClick = useCallback((item) => {
        onClick(item.id);
    }, [onClick]);
    return (_jsx(ActionSelect, { items: items, itemRenderer: renderActionItem, onItemSelect: handleClick, popoverProps: sharedPopoverProps, filterable: false, children: _jsx(FieldInput, { className: clsMb30, readOnly: true, value: itemsMap[value], label: "Record type" }) }));
});
