import { jsx as _jsx } from "react/jsx-runtime";
import { Icon } from '@blueprintjs/core';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'common/components/client-pagination-table';
import { LinkButton } from 'common/components/links/link-button';
import { NonEmptyString } from 'common/components/non-empty-string';
import { join } from 'common/helpers/router';
import { createSearchFunction, searchRankings } from 'common/helpers/search';
import { boolCompareAsc, stringCompareAsc } from 'common/helpers/sort';
import { clsLink } from 'common/styles/link';
import { AppDelete } from '../delete';
const clsIconTick = css `
  color: #4db690;
`;
const createColumns = (store) => [
    {
        id: 'name',
        title: 'Name',
        width: '100%',
        paddingVertical: 0,
        sorter: (a, b) => stringCompareAsc(a.name, b.name),
        render: ({ appId, name }) => (_jsx(Link, { className: clsLink, to: join('/b2b/api-keys', appId), children: _jsx(NonEmptyString, { value: name }) })),
    },
    {
        id: 'sandbox',
        title: 'Sandbox',
        align: 'center',
        sorter: (a, b) => boolCompareAsc(a.isTrial, b.isTrial),
        render: ({ isTrial }) => (isTrial ? _jsx(Icon, { icon: "tick-circle", iconSize: 20, className: clsIconTick }) : null),
    },
    {
        id: 'test',
        title: 'Test',
        align: 'center',
        sorter: (a, b) => boolCompareAsc(a.isTest, b.isTest),
        render: ({ isTest }) => (isTest ? _jsx(Icon, { icon: "tick-circle", iconSize: 20, className: clsIconTick }) : null),
    },
    {
        id: 'action',
        title: 'Action',
        align: 'center',
        render: (app) => _jsx(AppDelete, { app: app, onDeleted: store.handleAppDeleted }),
    },
];
const searchFunction = createSearchFunction([
    (app) => app.name,
    { key: (app) => app.appId, threshold: searchRankings.EQUAL },
]);
const DEFAULT_SORT = { sortKey: 'name', sortOrder: 'asc' };
export const AppsTable = observer(({ store, businessId }) => {
    const columns = useMemo(() => createColumns(store), [store]);
    useEffect(() => {
        void store.loadApps({ businessId });
    }, [store, businessId]);
    return (_jsx(Table, { data: store.apps, columns: columns, rowKey: "appId", searchFunction: searchFunction, defaultSort: DEFAULT_SORT, loader: store.loader, headerAction: _jsx(LinkButton, { to: `/b2b/api-keys/new/?businessId=${businessId !== null && businessId !== void 0 ? businessId : ''}`, text: "Add new" }) }));
});
