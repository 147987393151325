import { Classes } from '@blueprintjs/core';
import { css } from 'linaria';
import { COLORS } from 'common/helpers/colors';
export const SCROLL_SIZE = 12;
export const clsApp = css `
  min-height: 100vh;
`;
export const clsSpinner = css `
  min-height: 100vh;
`;
export const clsGlobals = css `
  :global() {
    & *,
    & *:before,
    & *:after {
      box-sizing: border-box;
      -webkit-touch-callout: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent;
    }

    & html,
    & body {
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }

    & html {
      background-color: #f7fafc;
      overflow-y: scroll;

      /* Firefox */
      scrollbar-width: thin;
      scrollbar-color: ${COLORS.BLUEBERRY_400} transparent;

      --main-button-color: ${COLORS.GREEN};
      --main-button-color-hover: ${COLORS.GREEN_DARK};
      --main-button-color-disabled: ${COLORS.BLUEBERRY_400};
    }

    /* Chrome, Edge, Safari  */
    &::-webkit-scrollbar {
      width: ${SCROLL_SIZE}px;
      height: ${SCROLL_SIZE}px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      border-left: 6px solid transparent;
      border-right: 2px solid transparent;
      border-top: 6px solid transparent;
      border-bottom: 2px solid transparent;
      background-color: ${COLORS.BLUEBERRY_400};

      &:hover {
        border-left: 2px solid transparent;
        border-top: 2px solid transparent;
        border-radius: 10px;
      }
    }

    & body {
      color: #333;
      font-size: 16px;
    }

    & a {
      transition: color 200ms ease-out;
      &:hover {
        text-decoration: none;
        color: #4db690;
      }
    }

    & .${Classes.POPOVER} {
      width: 100% !important;
      & .${Classes.MENU} {
        max-width: none !important;
      }
    }

    & .${Classes.DIALOG} {
      font-size: 16px;
    }

    & .${Classes.DIALOG_BODY} {
      line-height: 1.5;
    }

    & .bp3-tag {
      border-radius: 50px;
    }

    & .bp3-button {
      border-radius: 50px;

      &.bp3-small {
        padding: 2px 16px;
      }

      &.bp3-intent-success,
      &.bp3-intent-danger,
      &.bp3-intent-primary,
      &.bp3-intent-warning {
        &,
        &:hover {
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2);
        }
      }

      /* stylelint-disable no-descending-specificity */
      &.bp3-intent-success,
      &.bp3-intent-primary {
        background-color: var(--main-button-color);

        &:hover {
          background-color: var(--main-button-color-hover);
        }

        &:disabled {
          background-color: var(--main-button-color-disabled);
        }
      }

      &.bp3-intent-danger {
        background-color: #ee4055;

        &:hover {
          background-color: #c73c47;
        }
      }
    }
  }
`;
