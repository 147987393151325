import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonGroup, Intent } from '@blueprintjs/core';
import { isDefined } from '@whisklabs/typeguards';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { ButtonAdd, ButtonSave } from 'common/components/buttons';
const clsContainer = css `
  width: 100%;
`;
export const RowActions = observer((props) => {
    switch (props.rowKind) {
        case 'new': {
            const { form, addLoader } = props;
            return (_jsx(ButtonGroup, { className: clsContainer, children: _jsx(ButtonAdd, { onClick: form.submit, loading: addLoader === null || addLoader === void 0 ? void 0 : addLoader.isPending, disabled: !form.isValid, large: true }) }));
        }
        default: {
            const { form, saveLoader, onRemove, removeLoader, removeDisabled } = props;
            return (_jsxs(ButtonGroup, { className: clsContainer, children: [_jsx(ButtonSave, { onClick: form.submit, loading: saveLoader === null || saveLoader === void 0 ? void 0 : saveLoader.isPending, disabled: !form.isValidAndDirty || (removeLoader === null || removeLoader === void 0 ? void 0 : removeLoader.isPending), large: true }), isDefined(onRemove) ? (_jsx(Button, { onClick: onRemove, loading: removeLoader === null || removeLoader === void 0 ? void 0 : removeLoader.isPending, disabled: removeDisabled || (saveLoader === null || saveLoader === void 0 ? void 0 : saveLoader.isPending), icon: "trash", title: "Remove", intent: Intent.NONE, large: true })) : null] }));
        }
    }
});
