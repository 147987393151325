import { jsx as _jsx } from "react/jsx-runtime";
import { Button, Intent } from '@blueprintjs/core';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { Table } from 'common/components/infinite-scroll-table';
import { NonEmptyString } from 'common/components/non-empty-string';
const clsTable = css `
  word-break: break-all;
`;
export const DomainsTable = observer(({ store }) => {
    const columns = useMemo(() => [
        {
            id: 'domain',
            title: 'Domain',
            render: (domain) => (_jsx("code", { children: _jsx(NonEmptyString, { value: domain.domain }) })),
        },
        {
            id: 'userId',
            title: 'User Id',
            render: (domain) => { var _a; return _jsx(NonEmptyString, { value: (_a = domain.user) === null || _a === void 0 ? void 0 : _a.id }); },
        },
        {
            id: 'userName',
            title: 'User Name',
            render: (domain) => { var _a; return _jsx(NonEmptyString, { value: (_a = domain.user) === null || _a === void 0 ? void 0 : _a.name }); },
        },
        {
            id: 'actions',
            width: '175px',
            title: 'Actions',
            align: 'center',
            render: (domain) => (_jsx(Button, { rightIcon: "cross", onClick: () => store.deleteDomain({ domain: domain.domain }), large: true, intent: Intent.DANGER })),
        },
    ], [store]);
    useEffect(() => {
        store.loadInitialData();
    }, []);
    return (_jsx(Table, { loader: store.loader, tableClassName: clsTable, cellPaddingVertical: 10, data: store.claimedDomains, columns: columns, useScrollContentAsRoot: false, rowKey: "domain", loadMore: store.loadMoreData, hasMore: store.hasMoreData, isEmpty: store.claimedDomains.length === 0, emptyElement: _jsx("div", { children: "No items" }), isLoading: store.loader.isPending }));
});
