import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { LoadingWrapper } from 'common/components/loading-wrapper';
import { InfiniteScroll } from '../infinite-scroll';
import { NoData, TableBody, TableHead, TableLayout } from '../table-view';
const clsInfinite = css `
  overflow: initial;
`;
export const Table = observer(({ loader, rowKey, rowHeight, renderRow, data, cellPaddingVertical, cellPaddingHorizontal, columns, tableClassName, layout, ...infiniteScrollProps }) => {
    return (_jsx("div", { children: _jsx(LoadingWrapper, { loader: loader, pending: (loader === null || loader === void 0 ? void 0 : loader.isPending) && data.length === 0, children: data.length > 0 ? (_jsx(InfiniteScroll, { className: clsInfinite, ...infiniteScrollProps, children: _jsxs(TableLayout, { className: tableClassName, layout: layout, children: [_jsx(TableHead, { data: data, columns: columns, paddingVertical: cellPaddingVertical, paddingHorizontal: cellPaddingHorizontal }), _jsx(TableBody, { data: data, columns: columns, rowKey: rowKey, rowHeight: rowHeight, renderRow: renderRow, paddingVertical: cellPaddingVertical, paddingHorizontal: cellPaddingHorizontal })] }) })) : (_jsx(NoData, {})) }) }));
});
