import { jsx as _jsx } from "react/jsx-runtime";
import { AnchorButton } from '@blueprintjs/core';
import { isString, isText } from '@whisklabs/typeguards';
import { css } from 'linaria';
const clsAnchor = css `
  margin-left: 4px;

  && {
    text-align: left;
  }
`;
export const Link = ({ title, link, small }) => isString(link) && isText(title) ? (_jsx(AnchorButton, { text: title.trim(), rightIcon: "share", href: link, small: small, rel: "noreferrer", target: "_blank", className: clsAnchor })) : null;
