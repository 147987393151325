import { css } from 'linaria';
export const clsTd = css `
  display: table-cell;
  background: #fff;
  transition: all 200ms linear;
  font-size: 16px;
  border-bottom: 1px solid #e2e2ea;
`;
export const clsAlignMiddle = css `
  vertical-align: middle;
`;
export const clsAlignCenter = css `
  text-align: center;
`;
export const clsAlignLeft = css `
  text-align: left;
`;
export const clsAlignRight = css `
  text-align: right;
`;
export const clsAlignTop = css `
  vertical-align: top;
`;
export const clsAlignBottom = css `
  vertical-align: bottom;
`;
export const cellClasses = {
    top: clsAlignTop,
    bottom: clsAlignBottom,
    middle: clsAlignMiddle,
    right: clsAlignRight,
    left: clsAlignLeft,
    center: clsAlignCenter,
};
